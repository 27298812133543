import themeConfig from "@/themeConfig.js"
import env from '../env.js'
import countries from './db/countries.js'
import { getUserData } from '@/auth/utils'

// ////////////////////////////////////////////
// State
// /////////////////////////////////////////////

const state = {
    AppActiveUser: getUserData(),
    layout: {
        layoutType: themeConfig.layoutType,
        layoutWidth: themeConfig.layoutWidth,
        leftSidebarType: themeConfig.leftSidebarType,
        leftSidebarSize: themeConfig.leftSidebarSize,
        menuPosition: themeConfig.menuPosition,
        topbar: themeConfig.topbar,
        loader: themeConfig.loader,
        contentPageFixed: themeConfig.contentPageFixed
    },
    layoutReset: {
        width: themeConfig.layoutWidth,
        menu: themeConfig.menuPosition,
        sidebarType: themeConfig.leftSidebarType,
        sidebarSize: themeConfig.leftSidebarSize,
        topbar: themeConfig.topbar,
        checked: false,
    },
    showBreadcrumb:true,
    domain: env.domain,
    localUserKey: env.localUserKey,
    appName: env.appName,
    windowWidth: null,
    countries: countries,
    coupons: []
}

export default state
